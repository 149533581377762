var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "auth-wrapper auth-v1 px-2" }, [
    _c(
      "div",
      { staticClass: "auth-inner py-2" },
      [
        _c(
          "b-card",
          { staticClass: "mb-0" },
          [
            _c("b-link", { staticClass: "brand-logo mt-4 mb-4" }, [
              _c("img", {
                attrs: { src: _vm.style.loginImage, width: "150px" },
              }),
            ]),
            _c("b-card-text", { staticClass: "mb-2" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("Por favor, faça o login na sua conta.")) +
                  " "
              ),
            ]),
            _c("validation-observer", {
              ref: "loginForm",
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (ref) {
                    var invalid = ref.invalid
                    return [
                      _c(
                        "b-form",
                        {
                          staticClass: "auth-login-form mt-2",
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                "label-for": "email",
                                label: _vm.$t("Usuário"),
                              },
                            },
                            [
                              _c("validation-provider", {
                                attrs: {
                                  name: _vm.$t("Usuário"),
                                  rules: "required",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("b-form-input", {
                                            directives: [
                                              {
                                                name: "keyboard",
                                                rawName: "v-keyboard",
                                                value: { layout: "default" },
                                                expression:
                                                  "{ layout: 'default' }",
                                              },
                                            ],
                                            attrs: {
                                              id: "email",
                                              name: "username",
                                              state:
                                                errors.length > 0
                                                  ? false
                                                  : null,
                                              placeholder: _vm.$t("Usuário"),
                                              autofocus: "",
                                              tabindex: "1",
                                            },
                                            model: {
                                              value: _vm.userName,
                                              callback: function ($$v) {
                                                _vm.userName = $$v
                                              },
                                              expression: "userName",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-form-group",
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "d-flex justify-content-between",
                                },
                                [
                                  _c("label", { attrs: { for: "password" } }, [
                                    _vm._v(_vm._s(_vm.$t("Senha"))),
                                  ]),
                                  _c(
                                    "b-link",
                                    {
                                      attrs: {
                                        to: { name: "auth-forgot-password" },
                                      },
                                    },
                                    [
                                      _c("small", [
                                        _vm._v(
                                          _vm._s(_vm.$t("Esqueceu a senha?"))
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c("validation-provider", {
                                attrs: {
                                  name: _vm.$t("Senha"),
                                  rules: "required",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c(
                                            "b-input-group",
                                            {
                                              staticClass: "input-group-merge",
                                              class:
                                                errors.length > 0
                                                  ? "is-invalid"
                                                  : null,
                                            },
                                            [
                                              _c("b-form-input", {
                                                directives: [
                                                  {
                                                    name: "keyboard",
                                                    rawName: "v-keyboard",
                                                    value: {
                                                      layout: "default",
                                                    },
                                                    expression:
                                                      "{ layout: 'default' }",
                                                  },
                                                ],
                                                staticClass:
                                                  "form-control-merge",
                                                attrs: {
                                                  id: "password",
                                                  type: _vm.passwordFieldType,
                                                  state:
                                                    errors.length > 0
                                                      ? false
                                                      : null,
                                                  name: "password",
                                                  placeholder: _vm.$t("Senha"),
                                                  tabindex: "2",
                                                  "data-private": "",
                                                },
                                                model: {
                                                  value: _vm.password,
                                                  callback: function ($$v) {
                                                    _vm.password = $$v
                                                  },
                                                  expression: "password",
                                                },
                                              }),
                                              _c(
                                                "b-input-group-append",
                                                { attrs: { "is-text": "" } },
                                                [
                                                  _c("feather-icon", {
                                                    staticClass:
                                                      "cursor-pointer",
                                                    attrs: {
                                                      icon: _vm.passwordToggleIcon,
                                                    },
                                                    on: {
                                                      click:
                                                        _vm.togglePasswordVisibility,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                            1
                          ),
                          _c("e-button", {
                            staticClass: "mr-1",
                            attrs: {
                              "data-test": "LoginSubmitButton",
                              type: "submit",
                              variant: "primary",
                              block: "",
                              text: _vm.$t("Entrar"),
                              busy: _vm.busy,
                              disabled: invalid,
                            },
                            on: { click: _vm.login },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }